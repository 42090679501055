<template>
    <div align="center">
        <br><br>

        <div class="row justify-center">
            <div class="col-12 col-md-8">
                <QQButton label="Simpli da caricare" color="blue-grey" icon="mdi-clipboard-check-multiple-outline" size="xl" @click.native="onSimpliDaCaricare()"/>
                <QQButton label="Simpli da validare" color="blue-grey" icon="mdi-arrange-send-backward" size="xl" @click.native="onSimpliDaValidare()" v-if="isSede"/>
                <QQButton label="Simpli Validati" color="blue-grey" icon="mdi-arrange-send-backward" size="xl" @click.native="onSimpliValidati()"/>

            </div>

            <br><br><br><br><br>
        </div>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import policy from "@/libs/policy";

    export default {
        name: "MenuSimpli",
        data() {
            return {

            }
        },
        components: {
            QQButton
        },
        computed: {
            isSede() {
                return policy.isSede();
            }
        },
        methods: {
            onSimpliDaCaricare() {
                this.$router.push({name : "Simpli.SimpliDaCaricare"});
            },
            onSimpliValidati() {
                this.$router.push({name : "Simpli.SimpliValidati"});
            },
            onSimpliScartati() {
                this.$router.push({name : "Simpli.SimpliScartati"});
            },
            onSimpliDaValidare() {
                this.$router.push({name : "Simpli.SimpliDaValidare"});
            }
        },
        async mounted() {
        },
        async activated() {
        }
    }
</script>
